import _getSize from "get-size";
import _outlayer from "outlayer";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

/**
 * Isotope LayoutMode
 */
(function (window, factory) {
  // universal module definition

  /* jshint strict: false */

  /*globals define, module, require */
  if (exports) {
    // CommonJS
    exports = factory(_getSize, _outlayer);
  } else {
    // browser global
    window.Isotope = window.Isotope || {};
    window.Isotope.LayoutMode = factory(window.getSize, window.Outlayer);
  }
})(window, function factory(getSize, Outlayer) {
  'use strict'; // layout mode class

  function LayoutMode(isotope) {
    (this || _global).isotope = isotope; // link properties

    if (isotope) {
      (this || _global).options = isotope.options[(this || _global).namespace];
      (this || _global).element = isotope.element;
      (this || _global).items = isotope.filteredItems;
      (this || _global).size = isotope.size;
    }
  }

  var proto = LayoutMode.prototype;
  /**
   * some methods should just defer to default Outlayer method
   * and reference the Isotope instance as `this`
  **/

  var facadeMethods = ["_resetLayout", "_getItemLayoutPosition", "_manageStamp", "_getContainerSize", "_getElementOffset", "needsResizeLayout", "_getOption"];
  facadeMethods.forEach(function (methodName) {
    proto[methodName] = function () {
      return Outlayer.prototype[methodName].apply((this || _global).isotope, arguments);
    };
  }); // -----  ----- //
  // for horizontal layout modes, check vertical size

  proto.needsVerticalResizeLayout = function () {
    // don't trigger if size did not change
    var size = getSize((this || _global).isotope.element); // check that this.size and size are there
    // IE8 triggers resize on body size change, so they might not be

    var hasSizes = (this || _global).isotope.size && size;
    return hasSizes && size.innerHeight != (this || _global).isotope.size.innerHeight;
  }; // ----- measurements ----- //


  proto._getMeasurement = function () {
    (this || _global).isotope._getMeasurement.apply(this || _global, arguments);
  };

  proto.getColumnWidth = function () {
    this.getSegmentSize("column", "Width");
  };

  proto.getRowHeight = function () {
    this.getSegmentSize("row", "Height");
  };
  /**
   * get columnWidth or rowHeight
   * segment: 'column' or 'row'
   * size 'Width' or 'Height'
  **/


  proto.getSegmentSize = function (segment, size) {
    var segmentName = segment + size;
    var outerSize = "outer" + size; // columnWidth / outerWidth // rowHeight / outerHeight

    this._getMeasurement(segmentName, outerSize); // got rowHeight or columnWidth, we can chill


    if ((this || _global)[segmentName]) {
      return;
    } // fall back to item of first element


    var firstItemSize = this.getFirstItemSize();
    (this || _global)[segmentName] = firstItemSize && firstItemSize[outerSize] || // or size of container
    (this || _global).isotope.size["inner" + size];
  };

  proto.getFirstItemSize = function () {
    var firstItem = (this || _global).isotope.filteredItems[0];
    return firstItem && firstItem.element && getSize(firstItem.element);
  }; // ----- methods that should reference isotope ----- //


  proto.layout = function () {
    (this || _global).isotope.layout.apply((this || _global).isotope, arguments);
  };

  proto.getSize = function () {
    (this || _global).isotope.getSize();

    (this || _global).size = (this || _global).isotope.size;
  }; // -------------------------- create -------------------------- //


  LayoutMode.modes = {};

  LayoutMode.create = function (namespace, options) {
    function Mode() {
      LayoutMode.apply(this || _global, arguments);
    }

    Mode.prototype = Object.create(proto);
    Mode.prototype.constructor = Mode; // default options

    if (options) {
      Mode.options = options;
    }

    Mode.prototype.namespace = namespace; // register in Isotope

    LayoutMode.modes[namespace] = Mode;
    return Mode;
  };

  return LayoutMode;
});

export default exports;